.how-it-works {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;
}

.how-it-works h2 {
    color: #fff;
    margin: 40px 0;
    text-align: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 127.273% */
}

.how-it-works img {
    margin: 40px 0;
    width: 70%;
}


@media (max-width: 1300px) {
    .how-it-works {
        margin-top: 20px;
    }
}

@media (max-width: 768px) {
    .how-it-works {
        margin-top: 20px;
    }
}
