.navbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    position: relative;
}

.brand-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    /*transform: translateX(-20vw);*/
}

.logo-container {
    width: 50px;
    height: 50px;
    background-color: #1e2733;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.brand-name {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

.menu-icon {
    width: 30px;
    cursor: pointer;
}

.navbar-menu {
    display: none;
    flex-direction: column;
    z-index: 9;
}

.navbar-menu.is-open {
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: #1d2633;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.navbar-menu li {
    margin: 20px 0 0 0;
}

.navbar-menu li:first-child {
    margin-top: 0;
}

.navbar-menu a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
}

.navbar-menu a:hover {
    color: #ff9800;
}

.navbar-brand {
    min-height: 40px;
}

@media (min-width: 768px) {
    .navbar {
        flex-direction: row;
        justify-content: center;
    }

    .brand-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        transform: translateX(-20vw);
    }

    .menu-icon {
        display: none;
    }

    .navbar-menu {
        display: flex;
        flex-direction: row;
        position: static;
        box-shadow: none;
        background-color: transparent;
    }

    .navbar-menu.is-open {
        display: flex;
    }

    .navbar-menu ul {
        display: flex;
        flex-direction: row;
        gap: 44px;
    }

    .navbar-menu li {
        margin: 0 10px;
    }

    .navbar-menu a {
        font-size: 16px;
        opacity: 0.76;
    }

    .navbar-brand {
        display: none;
    }
}
