.hero {
    color: white;
    text-align: center;
    padding: 20px 20px;
}

.hero-content {
    /*max-width: 800px;*/
    margin: 0 auto;
}

.hero h3:first-child {
    width: 248px;
    position: relative;
    font-size: 28px;
    line-height: 36px;
    font-weight: 500;
    background: linear-gradient(180deg, #eb874e, #ffe251), #000;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    display: inline-block;
    margin-bottom: 0;
    z-index: -1;
}

.hero h1 {
    margin-bottom: -180px;
    text-align: center;
    margin-top: 10px;
    width: 100%;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 5rem; /* 119.403% */
    letter-spacing: -0.01563rem;
    background: linear-gradient(180deg, #FFF 38.5%, rgba(94, 229, 252, 0.30) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hero-info {
    width: 100%;
    height: 89vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: -20px;
}

.popup-container {
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 340px;
    transform: translateY(-200px);
    margin-top: -400px;
}

.popup-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.info-box {
    display: flex;
    width: 286px;
    padding: 0.75rem 1.4375rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 1.25rem;
    background: rgba(176, 230, 255, 0.06);
}

.left {
    transform: translateY(30px);
}

.info-box h2 {
    font-weight: 400;
    margin: 0;
    color: #FFF;
    font-size: 3.0625rem;
    font-style: normal;
    line-height: 3.3125rem; /* 108.163% */
}

.info-box h3 {
    margin: 0;
    color: #FFF;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; /* 147.368% */
}

.info-box span {
    width: 210px;
    color: #A8A8A8;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
}

.battery-icon {
    width: 100%;
    margin: 0 20px;
    z-index: 2;
    margin-top: -180px;
}

.left-pointer {
    margin-right: -130px;
    transform: translateY(50px);
}

.right-pointer {
    margin-left: -130px;
    z-index: 1;
}

.cta-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.hero-button {
    color: white;
    width: 288px;
    padding: 15px 32px;
    border: none;
    border-radius: 3.125rem;
    background: linear-gradient(90deg, #0059AB 0%, #39C1FF 100%);
    background-size: 200% 100%;
    background-position: 0% 100%;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-position 300ms;
}

.hero-button:hover {
    background-position: 100% 0%;
}

.button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.get-tonkeeper-button {
    color: white;
    width: 288px;
    padding: 15px 32px;
    background: transparent;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: 3.125rem;
    border: 1px solid #08A8F1;
}

.get-tonkeeper-button:hover {
    border-radius: 3.125rem;
    border: 1px solid #08A8F1;
    background: rgba(176, 230, 255, 0.06);
}

.back-shadow-container {
    position: absolute;
    width: 100%;
    height: 900px;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    z-index: -5;
}

.back-shadow {
    top: 0;
    position: absolute;
    width: 100%;
    height: 46em;
}


@media (max-width: 1625px) {

    .popup-container {
        transform: translateY(-200px);
        margin-top: -200px;
    }

    .hero-info {
        height: 55vh;
    }

    .hero h1 {
        margin-bottom: -20px;
    }
}
@media (max-width: 1226px) {

    .hero-info {
        height: 55vh;
    }

    .hero h1 {
        font-size: 3.8em;
        margin-bottom: -40px;
        margin-top: 0;
        line-height: 4.8rem; /* 119.403% */
    }

    .hero {
        overflow-x: hidden;
    }

    .battery-icon {
        /*width: 240px;*/
        width: 200%;
        margin-top: -200px;
        /*margin: 20px 20px;*/
    }

    .left {
        transform: translateY(0);
    }

    .left-pointer {
        display: none;
    }

    .right-pointer {
        display: none;
    }
    .popup-container {
        margin-top: 80px;
        transform: translateY(-30px);
        flex-direction: row;
        gap: 35px
    }

    .cta-container {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 35px;
    }
}


@media (max-width: 768px) {

    .hero {
        color: white;
        text-align: center;
        padding: 0 20px;
        overflow-x: hidden;
        margin-top: -20px;
    }

    .hero h3:first-child {
        width: 248px;
        position: relative;
        font-size: 16px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 0;
    }

    .hero h1 {
        font-size: 2.8em;
        margin-bottom: -40px;
        margin-top: 0;
        line-height: 3rem; /* 119.403% */
    }

    .hero-info {
        height: 40vh;
    }

    .battery-icon {
        /*width: 240px;*/
        width: 250%;
        margin-top: -100px;
        /*margin: 20px 20px;*/
    }

    .popup-container {
        margin-top: 20px;
        transform: translateY(-30px);
        flex-direction: column;
        gap: 20px
    }

    .back-shadow {
        top: 0;
        position: absolute;
        width: 200%;
        height: 32em;
    }

    .left {
        transform: translateY(0);
    }

    .left-pointer {
        display: none;
    }

    .right-pointer {
        display: none;
    }

    .info-box h1 {
        margin-bottom: 0;
    }

    .cta-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
}
