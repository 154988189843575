.benefits {
    color: #ffffff;
    text-align: center;
    padding: 40px 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.benefits h2 {
    color: #FFF;
    text-align: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 127.273% */
    margin-bottom: 80px;
}

.row {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1200px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 40px;
    margin-top: 80px;
}

.battery-phone {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.benefits-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 68px;
}

.benefit {
    display: flex;
    width: 20.125rem;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    border-radius: 1.25rem;
    background: rgba(176, 230, 255, 0.06);
}

.icon {
    margin-bottom: 0;
}

.icon img {
    width: 24px;
}

.description h3 {
    color: #FFF;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    margin-top: 12px;
}

.description p {
    color: #A8A8A8;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; /* 175% */
    text-align: left;
}


@media (max-width: 1300px) {

    .battery-phone {
        position: relative;
        left: 0;
        transform: translateX(0);
    }

    .row {
        justify-content: center;
        gap: 16px;
        margin-top: 50px;
        max-width: 700px;
    }

    .benefits-container {
        gap: 16px;
    }
}
@media (max-width: 768px) {
    .benefits h2 {
        margin-bottom: 50px;
    }

    .battery-phone {
        position: relative;
        width: 322px;
        margin-bottom: 50px;
    }

    .row {
        flex-direction: column;
        gap: 16px;
        margin-top: -20px;
    }

    .benefits-container {
        gap: 16px;
        flex-direction: column;
        align-items: center;
    }

    .benefit {
        width: 80%;
        max-width: 380px;
        /*margin-bottom: 20px;*/
    }
}
