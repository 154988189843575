.footer {
    color: #ffffff;
    padding: 40px 20px;
    text-align: center;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}

.footer-section {
    flex: 1;
    min-width: 150px;
    margin: 10px 0;
    text-align: left;
}

.footer-section h4 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 5px;
}

.footer-section a {
    color: #8994a3;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}

.footer-award {
    width: 200px;
    min-width: 150px;
    margin: 10px 0;
    text-align: right;
}

.footer-bottom {
    margin-top: 10px;
    padding-top: 20px;
    font-size: 0.9em;
}

.minor-text {
    color: #8994a3
}

.fading-line {
    display: flex;
    height: 1px;
    background: linear-gradient(90deg,transparent,#1d2633 50%,transparent);;
    padding: 0 24px;
}

.sections-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1 1;
    gap: 24px;
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .sections-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0
    }

    .footer-section, .footer-award {
        text-align: center;
    }

    .footer-section h4 {
        margin-top: 0;
    }
}
