
body {
    background: linear-gradient(180deg, #05070A 0%, #10161F 13.63%, #10161F 26.73%, #10161F 100%);
    overflow-x: hidden;
}

* {
    font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    z-index: 3;
    transition: 300ms;
    box-sizing: border-box;
}

