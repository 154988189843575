.closing-section {
    color: #ffffff;
    text-align: center;
    padding: 40px 20px;
    position: relative;
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -100px;
    margin-bottom: 80px;
}

.closing-section h3 {
    color: #FFF;
    text-align: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 127.273% */
    margin: 0;
}

.closing-section p {
    width: 24.375rem;
    color: #A8A8A8;
    margin-top: 12px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem; /* 175% */
}

.closing-fading-line {
    width: 400px;
    height: 1px;
    background: linear-gradient(90deg,transparent,#1d2633 50%,transparent);;
    padding: 0 24px;
    margin: 40px 0 20px 0;
    z-index: -2;
}

.download-link {
    color: #A8A8A8;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    /*line-height: 1.5rem; !* 171.429% *!*/
}

.download-link a {
    color: #08A8F1;
    text-decoration: none;
}

.arrow {
    transform: translateY(4px);
}

.download-link a:hover {
    text-decoration: underline;
}

.lightning-icons {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10px;
    left: 0;
    display: flex;
    justify-content: space-between;
    pointer-events: none; /* Ensure these icons do not interfere with button clicks */
}

.lightning-icon {
    width: 30px;
    height: auto;
}

.lightning-icon:first-of-type {
    margin-left: 10px;
}

.lightning-icon:last-of-type {
    margin-right: 10px;
}

.right-bottom {
    position: absolute;
    width: 2.65556rem;
    height: 4.14038rem;
    transform: rotate(-15deg) scaleX(-1);
    flex-shrink: 0;
    filter: blur(6px);
    right: 0;
    bottom: 90px;
}

.right-top {
    position: absolute;
    width: 5.8125rem;
    height: 9.0625rem;
    transform: rotate(15deg) scaleX(-1);
    flex-shrink: 0;
    right: -80px;
    top: 20px;
}

.left-bottom {
    position: absolute;
    width: 5.1875rem;
    height: 8.0625rem;
    flex-shrink: 0;
    left: 0;
    bottom: 60px;
}

.left-top {
    position: absolute;
    width: 3.46288rem;
    height: 5.39906rem;
    transform: rotate(-15deg) scaleX(-1);
    flex-shrink: 0;
    filter: blur(5px);
    left: -60px;
    top: 40px;
}

@media (max-width: 1226px) {
    .closing-section p {
        width: 300px;
    }

    .closing-fading-line {
        width: 200px;
    }

    .lightning-icons {
        top: 20px;
        justify-content: center;
        /*overflow-x: hidden;*/
        /*display: none;*/
    }

    .lightning-icon:first-of-type, .lightning-icon:last-of-type {
        margin: 0 20px;
        z-index: -1;
    }


    .right-bottom {
        right: 0;
        bottom: 90px;
    }

    .right-top {
        right: -55px;
        top: 20px
    }

    .left-bottom {
        left: -55px;
        bottom: 130px;
    }

    .left-top {
        left: -20px;
        top: 40px;
    }
}
@media (max-width: 768px) {
    .closing-section {
        margin-top: 0;
    }

    .closing-section p {
        width: 340px;
    }

    .closing-fading-line {
        width: 200px;
    }

    .lightning-icons {
        top: 20px;
        justify-content: center;
        overflow-x: hidden;
        /*display: none;*/
    }

    .lightning-icon:first-of-type, .lightning-icon:last-of-type {
        margin: 0 20px;
        z-index: -1;
    }


    .right-bottom {
        right: 0;
        bottom: 90px;
    }

    .right-top {
        right: -55px;
        top: 20px
    }

    .left-bottom {
        left: -55px;
        bottom: 130px;
    }

    .left-top {
        left: -20px;
        top: 40px;
    }

}
